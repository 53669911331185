import { setCookie } from "./helpers";

jQuery(document).ready(function ($) {
    $('#login-form').on('submit', function (event) {
        event.preventDefault();
        const email = $('#email').val();
        const password = $('#password').val();

        $.ajax({
            url: '/wp-json/championpoker/v1/authenticate/',
            method: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({ email: email, password: password }),
            success: function (response) {
                if (response.bearer_token) {
                    // Store session data in server-side session
                    $.ajax({
                        url: '/wp-json/championpoker/v1/store-session-data',
                        method: 'POST',
                        contentType: 'application/json',
                        data: JSON.stringify({ 
                            user_id: response.user.id, 
                            bearer_token: response.bearer_token,
                            system_settings: response.settings,
                            custom_settings: response.custom_settings
                        }),
                        success: function () {
                            // Set cookies if custom settings are present
                            if (response.custom_settings) {
                                try {
                                    const customSettings = JSON.parse(response.custom_settings);
                                    for (const [key, value] of Object.entries(customSettings)) {
                                        setCookie(key, value);
                                    }
                                } catch (e) {
                                    console.error('Failed to parse custom settings:', e);
                                }
                            }

                            const attemptedPath = window.sessionStorage.getItem('attemptedPath');
                            if (attemptedPath) {
                                window.location.href = attemptedPath;
                                window.sessionStorage.removeItem('attemptedPath');
                            } else {
                                window.location.href = '/backoffice/';
                            }
                        },
                        error: function () {
                            alert('Session storage failed');
                        }
                    });
                } else {
                    alert('Login failed: ' + (response.error || 'Invalid credentials'));
                }
            },
            error: function (xhr) {
                if (xhr.status === 503) {
                    alert('System under maintenance. Please try again later.');
                } else if (xhr.status === 401) {
                    alert('Invalid credentials. Please check your email and password.');
                } else {
                    alert('An unexpected error occurred. Please try again later.');
                }
            }
        });
    });

    function checkTokenExpiration() {
        $.ajax({
            url: '/wp-json/championpoker/v1/check-session',
            method: 'GET',
            success: function (response) {
                const sessionDataString = atob(response.data);
                const sessionData = JSON.parse(sessionDataString);

                if (!sessionData.valid) {
                    window.sessionStorage.setItem('attemptedPath', window.location.pathname);
                    window.location.href = '/login-panel/';
                } else {
                    // Schedule the next check before the token expires
                    const nextCheckInMs = sessionData.session_remaining_time * 1000 - 5000; // 5 seconds before expiration
                    setTimeout(checkTokenExpiration, Math.max(nextCheckInMs, 5000)); // Run every 5s as fallback
                }
            },
            error: function (xhr, status, error) {
                console.error('Failed to check session:', xhr.responseText, status, error);
            }
        });
    }

    // Start checking token expiration if not on login page
    if (!window.location.pathname.startsWith('/login-panel/')) {
        checkTokenExpiration();
    }
});
